import { useState, useEffect } from 'react';
import blsFirmsJSON from '../data/industries-by-firms-naics2.json'
import blsJobsJSON from '../data/industries-by-jobs-naics2.json'
import trsJSON from '../data/taxable-retail-sales-naics2.json'

const useFetchBLSData = (counties: string[]) => {
    const [data, setData] = useState<any>({ blsFirmsData: null, blsJobsData: null, trsData: null});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        const fetchBLSTRSData = async () => {
            if (counties === null || counties === undefined) {
                console.log('No counties provided to fetch BLS data');
                return;
            } 

            try {
                
                // Pretend we are querying or loading this data from another source
                // ...


                console.log("trsJSON: ", trsJSON);
                
                // Filter our json data by county
                const blsFirmsData: any = blsFirmsJSON;
                const countyBlsFirmsData = blsFirmsData.filter( (item: any) => counties.indexOf(item.CountyFIPS) >= 0);

                const blsJobsData: any = blsJobsJSON;
                const countyBlsJobsData = blsJobsData.filter( (item: any) => counties.indexOf(item.CountyFIPS) >= 0);

                // Note: trs data does not have the state prepended, so we will not compate against it
                var slimCounties = counties.map( (item: any) => item.slice(2));
                const trsData: any = trsJSON;
                const countyTrsData = trsData.filter( (item: any) => slimCounties.indexOf(item.CountyFIPS) >= 0);

                setData({ blsFirmsData: countyBlsFirmsData, blsJobsData: countyBlsJobsData, trsData: countyTrsData});

            } catch (error: any) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchBLSTRSData();
    }, [counties]);

    return { data, loading, error };
};

export default useFetchBLSData;