import { useState } from "react";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import SparkLineChart from "./SparkLineChart";
import { formatValue } from "../utils/helpers";



interface IndustryBLSTRSCharts {
    data: any
};

const IndustryBLSTRSCharts = ({ data }: IndustryBLSTRSCharts) => {
    const [chartData, setChartData] = useState<{ blsFirmsData: any, blsJobsData: any, trsData: any }>(data);
    const [blsActive, setBlsActive] = useState(true);
    const [trsActive, setTrsActive] = useState(false);

    const [blsFirmsActive, setBlsFirmsActive] = useState(false);
    const [blsJobsActive, setBlsJobsActive] = useState(true);

    const [blsFirmsData, setBlsFirmsData] = useState<any>(null);
    const [blsJobsData, setBlsJobsData] = useState<any>(null);
    const [blsRecentDate, setBlsRecentDate] = useState<string>();

    const [trsData, setTrsData] = useState<any>(null);
    const [trsRecentDate, setTrsRecentDate] = useState<string>();


    console.log("data: ", data);
    console.log("chartData: ", chartData);
    
    useDeepCompareEffect(() => {
  
        console.log("use deep compare: ", chartData);
        setChartData(data);

        formatData(chartData.blsFirmsData, setBlsFirmsData, setBlsRecentDate);
        formatData(chartData.blsJobsData, setBlsJobsData, setBlsRecentDate);
        formatData(chartData.trsData, setTrsData, setTrsRecentDate);
        
        
    }, [chartData]);


    const handleTabClick = (event: any) => {
        
        var type = event.target.getAttribute('data-type');
        switch(type) {
            case "trs": setTrsActive(true); setBlsActive(false); break;
            case "industry": setTrsActive(false); setBlsActive(true); break;
            
        }
        console.log("button type: ", type);
    }

    const handleBlsTypeClick = (event: any) => {

        var value = event.target.value;
        switch(value) {
            case "Firms": setBlsFirmsActive(true); setBlsJobsActive(false); break;
            case "Jobs": setBlsFirmsActive(false); setBlsJobsActive(true); break;
        }
        console.log("value: ", value);
    }

    const formatData = (data: any, setData: any, setRecent: any) => {
        
        // Render our chart
        var labelLookup: any = [];
        labelLookup["Construction"] = "Construction & Resources (Conts/Res)";
        labelLookup["Education"] = "Education";
        labelLookup["FIRE"] = "Finance, Insurance, and Real Estate (FIRE)";
        labelLookup["Government"] = "Government";
        labelLookup["Manufacturing"] = "Manufacturing";
        labelLookup["Retail"] = "Retail";
        labelLookup["Services"] = "Services";
        labelLookup["WTU"] = "Wholesale Trade, Transportation, and Utilities (WTU)";

        // Aggregate values by year/quarter into a lookup
        // There are multiple rows for each of the ownership codes
        var totalCount = 0;
        var totalsLookup: any = { "Construction": null, "Education": null, "FIRE": null, "Government": null, "Manufacturing": null, "Retail": null, "Services": null, "WTU": null };
        var lookup: any = [];


        var lbls = Object.keys(labelLookup);

        for (var i = 0; i < data.length; i++) {

            // If year/quarter doesn't exist, we will add it
            if (lookup[data[i].Year] == undefined) {
                lookup[data[i].Year] = {};
            }
            if (lookup[data[i].Year][data[i].Quarter] == undefined) {
                lookup[data[i].Year][data[i].Quarter] = { "Construction": null, "Education": null, "FIRE": null, "Government": null, "Manufacturing": null, "Retail": null, "Services": null, "WTU": null };
            }

            for (var z = 0; z < lbls.length; z++) {
                // Only zero out our data if we have values to add. This allows for overall NULL displays if we have no data
                if (data[i][lbls[z]] != null) {
                    if (lookup[data[i].Year][data[i].Quarter][lbls[z]] == null) {
                        lookup[data[i].Year][data[i].Quarter][lbls[z]] = 0;
                    }
                    lookup[data[i].Year][data[i].Quarter][lbls[z]] += data[i][lbls[z]];
                }
            }
        }

        // Create lookup by label for timeline data
        var dataDateString = "n/a";
        var labelTimelineLookup: any = [];
        var labelKeys = lbls;
        var yearKeys = Object.keys(lookup);
        yearKeys = yearKeys.sort();

        for (var lk = 0; lk < labelKeys.length; lk++) {
            labelTimelineLookup[labelKeys[lk]] = [];

            for (var yk = 0; yk < yearKeys.length; yk++) {
                var qKeys = Object.keys(lookup[yearKeys[yk]]);
                qKeys.sort();

                for (var qk = 0; qk < qKeys.length; qk++) {
                    labelTimelineLookup[labelKeys[lk]].push({ label: yearKeys[yk] + " - Q" + qKeys[qk], value: lookup[yearKeys[yk]][qKeys[qk]][labelKeys[lk]] });
                }
            }
        }

        // Set our totals for most recent year
        for (var w = 0; w < labelKeys.length; w++) {
            totalsLookup[labelKeys[w]] = labelTimelineLookup[labelKeys[w]][labelTimelineLookup[labelKeys[w]].length - 1].value;
            totalCount += labelTimelineLookup[labelKeys[w]][labelTimelineLookup[labelKeys[w]].length - 1].value;
            dataDateString = labelTimelineLookup[labelKeys[w]][labelTimelineLookup[labelKeys[w]].length - 1].label;
        }
        setRecent(dataDateString);

        // Sort our label/value pairs from highest to lowest job counts
        var sortedPercentages = [];
        for (var j = 0; j < labelKeys.length; j++) {
            var key = labelKeys[j];
            var pct = null;
            if (totalCount > 0 && totalsLookup[key] != null) {
                pct = totalsLookup[key] / totalCount;
            }
            sortedPercentages.push({ label: labelLookup[key], average: pct, total: totalsLookup[key], timelineData: labelTimelineLookup[key] });
        }
        sortedPercentages = sortedPercentages.sort(function(a, b) {
            return -(a.total > b.total )||+(a.total < b.total);
        });

        console.log("bls firms data: ", sortedPercentages);
        setData(sortedPercentages);
    }

    return (
        <div>
            <div className="btn-group search-type-toggle mb-2 text-center">
                <button onClick={handleTabClick} className={`${ blsActive ? "bg-color-cai-dashboard text-white" : "bg-white" } w-1/2 px-3 font-bold text-xs border border-solid border-color-cai-dashboard rounded-[4px] rounded-r-none focus:outline-none focus:border-blue-500 h-8 appearance-none text-center active`} data-type="industry">Industry</button>
                <button onClick={handleTabClick} className={`${ trsActive ? "bg-color-cai-dashboard text-white" : "bg-white" } w-1/2 px-3 font-bold text-xs border border-solid border-color-cai-dashboard rounded-[4px] rounded-l-none focus:outline-none focus:border-blue-500 h-8 appearance-none text-center btn-search-type`} data-type="trs">Taxable Retail Sales</button>
            </div>
            <div className="flex flex-col w-full border rounded-[4px] mt-4 border-color-cai-dashboard bg-gray-200">
                
                <div className={`w-full p-4 ${ blsActive ? "" : "hidden" }`}>
                    <div className="font-bold text-xs pb-2 border-b-[1px] border-[#DDDDDD]">Industries by Number of: 
                        <label className="pl-4"><input onClick={handleBlsTypeClick} className="ind-by-radio" type="radio" name="IndByRadio" value="Jobs" defaultChecked={blsJobsActive} /> Jobs</label> 
                        <label className="pl-4"><input onClick={handleBlsTypeClick} className="ind-by-radio" type="radio" name="IndByRadio" value="Firms" defaultChecked={blsFirmsActive} /> Firms</label>
                    </div>
                    {/* <div className="text-[.7rem] italic text-gray-400">Quarterly data for <span className="cf-county"></span> <span className="cf-plural"></span></div> */}
                    <table className={`table naics-table ${ blsFirmsActive ? "" : "hidden" }`}>
                        <tbody>
                            {blsFirmsData?.map((item: any) =>
                                <tr className="text-xs pb-2 border-b-[1px] border-[#DDDDDD] last:border-b-0" key={item.label}>
                                    <td className="w-[10rem] pr-2">{item.label}</td>
                                    <td>{formatValue(item.average, 'percentage')}</td>
                                    <td className="pr-2"><SparkLineChart data={item.timelineData} height="h-[4rem]" width="w-40" /></td>
                                    <td>{formatValue(item.total, 'integer')}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <table className={`table naics-table ${ blsJobsActive ? "" : "hidden" }`}>
                        <tbody>
                            {blsJobsData?.map((item: any) =>
                                <tr className="text-xs pb-2 border-b-[1px] border-[#DDDDDD] last:border-b-0" key={item.label}>
                                    <td className="w-[10rem] pr-2">{item.label}</td>
                                    <td>{formatValue(item.average, 'percentage')}</td>
                                    <td className="pr-2"><SparkLineChart data={item.timelineData} height="h-[4rem]" width="w-40" /></td>
                                    <td>{formatValue(item.total, 'integer')}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <div className="text-xs mt-2">Most recent data: <span>{blsRecentDate}</span></div>
                </div>
                <div className={`w-full p-4 ${ trsActive ? "" : "hidden" }`}>
                    <div className="font-bold text-xs pb-2 border-b-[1px] border-[#DDDDDD]">Taxable Retail Sales</div>
                    {/* <div className="text-[.7rem] italic text-gray-400">Quarterly data for <span className="cf-county"></span> <span className="cf-plural"></span></div> */}
                    <table id="TaxableRetailSalesNAICS2" className="table naics-table">
                        <tbody>
                            {trsData?.map((item: any) =>
                                <tr className="text-xs pb-2 border-b-[1px] border-[#DDDDDD] last:border-b-0" key={item.label}>
                                    <td className="w-[10rem] pr-2">{item.label}</td>
                                    <td>{formatValue(item.average, 'percentage')}</td>
                                    <td className="pr-2"><SparkLineChart data={item.timelineData} height="h-[4rem]" width="w-40" /></td>
                                    <td>{formatValue(item.total, 'integer')}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <div className="text-xs mt-2">Most recent data: <span>{trsRecentDate}</span></div>
                </div>
            </div>
        </div>
    );
};


export default IndustryBLSTRSCharts;
