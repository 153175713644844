//import { formatValue } from "../utils/helpers";
//import metricMetadataIcon from '../assets/metric-metadata-icon.svg';
import useFetchMetricMetadata from "../hooks/useFetchMetricMetadata";
import { useEffect, useState } from "react";
import MetricMetadataModal from "./MetricMetadataModal";

interface LegendMetricModalProps {
    id: number;
    isOpen: boolean;
    onClose: any;
    svg: string;
};

const LegendMetricModal = ({ id, isOpen, onClose, svg }: LegendMetricModalProps) => {

    const [showMetadata, setShowMetadata] = useState(isOpen);
    //const [svg, setSvg] = useState('');
    const { data: metadata } = useFetchMetricMetadata(id);


    useEffect(() => {

        // If we have a valid id
        if(id > 0 && metadata != "query in progress") {
            setShowMetadata(true);
        }

    }, [metadata]);

    const handleClick = () => {
        setShowMetadata(false);
        onClose();
    }

    return (
        <div>
        <MetricMetadataModal
            isOpen={showMetadata}
            onClose={handleClick}
            metadata={metadata}
            svg={svg}
        />
        </div>
    );
};


export default LegendMetricModal;
