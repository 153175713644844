import ReactDOM from 'react-dom';

interface MetricVersion {
    metric_version_id_ext: number;
    year: string;
    is_default: string;
    level_of_detail: string;
    source: string;
    source_url: string;
    why_important: string;
    polarity: string;
    aggregation: string;
    unit: string;
    unit_descriptor: string;
}

interface MetricMetadata {
    id: number;
    metric_id_ext: number;
    metric_name: string;
    metric_category: string;
    definition: string;
    bin_range_colors: string;
    mappable: boolean;
    metric_source_type: string;
    versions: MetricVersion[];
    disaggregations: any[];
}

interface MetricMetadataModalProps {
    isOpen: boolean;
    onClose: () => void;
    metadata: MetricMetadata[];
    svg: string
}

const MetricMetadataModal: React.FC<MetricMetadataModalProps> = ({ isOpen, onClose, metadata, svg }) => {

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div className={`fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 transition-all duration-500 transform ${!isOpen ? 'translate-y-full' : 'translate-y-0'}`}>
            <div className={`bg-white p-6 w-full h-full relative transition-transform duration-500 transform ${!isOpen ? 'translate-y-full' : 'translate-y-0'}`}>
                <button
                    className="absolute top-3 right-5 text-gray-600 text-2xl hover:text-black"
                    onClick={onClose}
                    aria-label="Close modal"
                >
                    &times;
                </button>
                {metadata && metadata.length > 0 ? (
                    <div>
                        {metadata.map((metric) => (
                            <div key={metric.id} className="mb-4">
                                <img src={svg} className='h-10 w-10'></img>
                                <h3 className="text-color-cai-main text-sm font-bold">{metric.metric_name}</h3>
                                <p className='text-color-cai-dashboard text-xs'>{metric.definition}</p>
                                {metric.versions && metric.versions.length > 0 && (
                                    <div>
                                        {metric.versions.map((version) => (
                                            <div key={version.metric_version_id_ext} className="my-4">
                                                <div className='flex w-full'>
                                                    <div className='flex flex-col w-full'>
                                                        <p className='text-color-cai-main font-bold text-sm'>Data Year</p>
                                                        <p className='text-color-cai-dashboard text-xs'> {version.year}</p>
                                                    </div>
                                                    <div className='flex flex-col w-full'>
                                                        <p className='text-color-cai-main font-bold text-sm'>Scope</p>
                                                        <p className='text-color-cai-dashboard text-xs'> {version.level_of_detail}</p>
                                                    </div>
                                                </div>
                                                <p className='text-color-cai-main font-bold text-sm mt-4'>Why Include This Metric?</p>
                                                <p className='text-color-cai-dashboard text-xs'>{version.why_important}</p>
                                                <p className='text-color-cai-main font-bold text-sm mt-4'>Source</p>
                                                <p className='text-color-cai-dashboard text-xs'>{version.source}</p>
                                                <p className='text-[#337AB7] text-xs mt-5'><a href={version.source_url} target="_blank" rel="noopener noreferrer">{version.source_url}</a></p>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                ) : (
                    <p>No metadata available.</p>
                )}
            </div>
        </div>,
        document.body
    );
};

export default MetricMetadataModal;