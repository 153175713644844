import { useState } from 'react';
import { BoundingBox, DisaggregatedMetric, InteractionData, LayerDetail, Metric } from '../services/types';
//import { getInteractionsLayerId } from "../utils/dashboardUtils";
import useDeepCompareEffect from './useDeepCompareEffect';

const useFetchAllIndicators = () => {
    const [indicatorsData, setIndicatorsData] = useState<Metric[]>([]);
    const [disaggregatedIndicators, setDisaggregatedIndicators] = useState<DisaggregatedMetric[]>([]);
    const [interactionData, setInteractionData] = useState<InteractionData[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [orgId, setOrgId] = useState<number | null>(null);
    const [geoIds, setGeoIds] = useState<string[]>([]);
    const [geoLod, setGeoLod] = useState('');
    const [boundingBox, setBoundingBox] = useState<BoundingBox | null>(null);
    const [layerDetails, setLayerDetails] = useState<LayerDetail[] | null>(null);
    const [excludeIds, setExcludeIds] = useState<string | null>(null);
    const [pdfUrl, setPdfUrl] = useState('');

    const indicatorsUrl = 'https://cai-dashboard-backend.vercel.app/api/getindicators';
    const interactionUrl = 'https://cai-dashboard-backend.vercel.app/api/getinteractionsummary';
    const disaggregatedUrl = 'https://cai-dashboard-backend.vercel.app/api/getdisaggregatedindicators';
    const interactionsLayerIdUrl = 'https://cai-dashboard-backend.vercel.app/api/getinteractionslayerid';

    useDeepCompareEffect(() => {
        const handleMessage = async (event: MessageEvent) => {
            console.log('Received message:', event.data);

            if (event.data.message === 'update') {
                const { params } = event.data;

                if (params) {
                    setOrgId(params.organization_id || 12);
                    setGeoLod(params.area_view.geo_lod || '');
                    setGeoIds(params.area_view.geo_ids || []);
                    setBoundingBox(params.area_view.bounding_box);
                    
                    setLayerDetails(params.layer_details || null);


                    // Fetch layer id
                    if(params.organization_id !== null){

                        // Quick hack.. orgId is null for some reason. Fix later :(


                        //const layerOrgId = 13; //params.organization_id;
                        const layerIdResponse = await fetch(interactionsLayerIdUrl, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({ orgId: params.organization_id }),
                        });
    
                        if (!layerIdResponse.ok) {
                            throw new Error('Network response was not ok');
                        }
    
                        const layerIdResult = await layerIdResponse.json();
                        console.log("layerIdResult: ", layerIdResult);
                        const layerId = layerIdResult;
    
                        console.log("LayerId; ", layerId);
    
                        //var layerId = await getInteractionsLayerId();
    
                        const layer = params.layer_details?.find((layer: LayerDetail) => layer.layer_id === layerId) || null;
                        setExcludeIds(layer?.exclude_ids?.join(',') || '');
                    }
                }
            }

            if (event.data.message === 'pdf') {
                const { params } = event.data;

                if (params) {
                    setPdfUrl(params.url || '');
                }
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    useDeepCompareEffect(() => {
        const fetchData = async () => {
            try {
                if (orgId !== null) {

                    console.log('Fetching All Indicators...')
                    // Fetch indicators data
                    const indicatorsResponse = await fetch(indicatorsUrl, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ geoIds, geoLod, orgId }),
                    });

                    if (!indicatorsResponse.ok) {
                        throw new Error('Network response was not ok');
                    }

                    const indicatorsResult = await indicatorsResponse.json();
                    setIndicatorsData(indicatorsResult.data);


                    // Fetch disaggregated data
                    const disaggregatedResponse = await fetch(disaggregatedUrl, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ geoIds, geoLod, orgId }),
                    });

                    if (!disaggregatedResponse.ok) {
                        throw new Error('Network response was not ok');
                    }

                    const disaggregatedResult = await disaggregatedResponse.json();
                    setDisaggregatedIndicators(disaggregatedResult.data);


                    // Fetch layer id
                    const layerIdResponse = await fetch(interactionsLayerIdUrl, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ orgId }),
                    });

                    if (!layerIdResponse.ok) {
                        throw new Error('Network response was not ok');
                    }

                    const layerIdResult = await layerIdResponse.json();
                    console.log("layerIdResult: ", layerIdResult);
                    const layerId = layerIdResult;

                    const intLayer = layerDetails?.find((layer: LayerDetail) => layer.layer_id === layerId) || null;
                    const startDate = intLayer?.start_date;
                    const endDate = intLayer?.end_date;

                    const interactionResponse = await fetch(interactionUrl, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        //body: JSON.stringify({ boundingBox, excludeIds, layerId }),
                        body: JSON.stringify({ boundingBox, excludeIds, layerId, startDate, endDate }),
                    });

                    // Hack to get all interactions so we can calculate how many we are not showing
                    const allInteractionResponse = await fetch(interactionUrl, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ layerId }),
                    });
                    
                    if (!interactionResponse.ok || !allInteractionResponse.ok) {
                        throw new Error('Network response was not ok');
                    }
                    
                    const interactionResult = await interactionResponse.json();
                    const allInteractionResult = await allInteractionResponse.json();

                    // We need to sneak in a parameter to get our 'missing' count
                    console.log("interactionResult: ", interactionResult);
                    if(interactionResult.results > 0){
                        interactionResult.data[0].item_missing_count = allInteractionResult.data[0].item_count - interactionResult.data[0].item_count;
                    }

                    setInteractionData(interactionResult.data);

                }
            } catch (error: any) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [geoIds, geoLod, orgId, boundingBox, excludeIds, pdfUrl]);

    return { indicatorsData, disaggregatedIndicators, interactionData, loading, error, pdfUrl };
};

export default useFetchAllIndicators;