import { formatValue } from "../utils/helpers";
import metricMetadataIcon from '../assets/metric-metadata-icon.svg';
import useFetchMetricMetadata from "../hooks/useFetchMetricMetadata";
import { useState } from "react";
import MetricMetadataModal from "./MetricMetadataModal";

interface MetricCardProps {
    id: number;
    title: string;
    value: number;
    unit: string;
    svg: string;
    showMetadataIcon?: boolean;
};

const MetricCard = ({ id, title, value, unit, svg, showMetadataIcon = true }: MetricCardProps) => {
    const [showMetadata, setShowMetadata] = useState(false);
    const { data: metadata } = useFetchMetricMetadata(id);

    const handleClick = () => {
        setShowMetadata(prev => !prev);
        console.log('metricid:', id)
    }

    return (
        <div className="ml-2 mr-4 my-2">
            <div className="flex flex-col items-start w-full h-28">
                <div className="flex items-end w-24 justify-between">
                    <img src={svg} alt={`${title} icon`} className="w-10 h-10 text-blue-500" />
                    {showMetadataIcon && 
                    <button onClick={handleClick}>
                        <img src={metricMetadataIcon} alt="Metric metadata icon" />
                    </button>
                    }
                </div>
                <p className="text-color-cai-gray text-sm font-bold w-[7.5rem]">{title}</p>
                <div className="flex items-baseline w-full justify-start">
                    <h2 className="text-color-cai-dashboard text-2xl font-bold">{formatValue(value, unit)}</h2>
                </div>
            </div>
            <MetricMetadataModal
                isOpen={showMetadata}
                onClose={handleClick}
                metadata={metadata}
                svg={svg}
            />
        </div>
    );
};


export default MetricCard;
