import { memo, useRef, useState } from "react";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import { DisaggregatedMetric } from "../services/types";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getMetricGroupChartId } from "../utils/dashboardUtils";
import { formatValue } from "../utils/helpers";
import { Chart } from "chart.js";

interface ImmigrationChartProps {
    disaggregatedData: DisaggregatedMetric[];
}

const ImmigrationChart = memo(({ disaggregatedData }: ImmigrationChartProps) => {
    const [data, setData] = useState<{ label: string, value: number, rawValue: number }[]>([]);
    const [barColors, setBarColors] = useState<string[]>([]);
    const title = "Top Countries of Immigration";
    const height = "h-32";
    const backgroundColor = '#626262';
    const formatter=(value: any) => `${formatValue(value, 'percentage')}`

    useDeepCompareEffect(() => {
        if (!disaggregatedData) return;

        const immigrationData = disaggregatedData.find(data => data.id === getMetricGroupChartId("Demographics", "immigration"));
        
        if (!immigrationData) return;

        const version = immigrationData.versions[0];
        if (!version) return;

        const tallyMap = new Map<string, number>();
        const rawValueMap = new Map<string, number>();

        version.disaggregations.forEach(d => {
            const percentage = d.value / version.value;
            tallyMap.set(d.name.trim(), percentage);
            rawValueMap.set(d.name.trim(), d.value); // Store the raw value
        });

        // Convert to an array, sort by value in descending order, and take the top 5
        const topFive = Array.from(tallyMap.entries())
            .map(([name, percentage]) => ({
                label: name,
                value: percentage,
                rawValue: rawValueMap.get(name) ?? 0
            }))
            .sort((a, b) => b.value - a.value) // Sort in descending order
            .slice(0, 5); // Take top 5
            

        // set barColors
        const barColors = topFive.map(_ =>'#626262');

        setData(topFive);
        setBarColors(barColors);
    }, [disaggregatedData]);


    // Register the plugin to all charts:
    Chart.register(ChartDataLabels);
    const chartRef = useRef<HTMLCanvasElement>(null);
    const chartInstance = useRef<Chart<"bar"> | null>(null);

    useDeepCompareEffect(() => {
        const ctx = chartRef.current?.getContext("2d");
        if (ctx) {
            // Destroy existing chart instance if it exists
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }

            // Extract labels and data
            const labels = data.map(d => d.label);
            const chartData = data.map(d => d.value);

            // Set max and min values for label padding
            const maxValue = Math.max(...chartData) * 1.5;
            const minValue = -maxValue * .25;

            // Use provided barColors or fallback to backgroundColor for all bars
            const colors = barColors && barColors.length === data.length
                ? barColors
                : new Array(data.length).fill(backgroundColor);

            chartInstance.current = new Chart(ctx, {
                type: "bar",
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: title,
                            data: chartData,
                            backgroundColor: colors,
                        },
                    ],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,  // Allows the chart to take the full height
                    indexAxis: 'y',
                    plugins: {
                        legend: {
                            display: false,
                        },
                        datalabels: {
                            anchor: 'start',
                            align: 'start',
                            formatter: formatter || ((value) => value ? `${value.toFixed(0)}` : ''),
                            font: {
                                size: 12,
                                weight: 'bold'
                            },
                            offset: 0
                        },
                        tooltip: {
                            callbacks: {
                                label: (context) => {
                                    const rawValue = data[context.dataIndex].rawValue; // Get the raw value from the data array
                                    return `${formatValue(rawValue, 'integer')}`; // Display raw value in tooltip
                                }
                            }
                        },
                    },
                    scales: {
                        x: {
                            beginAtZero: true,
                            min: minValue,
                            max: maxValue,
                            ticks: {
                                display: false,
                            },
                            grid: {
                                display: false,
                                drawTicks: false
                            },
                            border: {
                                display: false,
                                dash: [2, 4]
                            }
                        },
                        y: {
                            grid: {
                                display: false,
                                drawTicks: true,
                            },
                            border: {
                                display: false
                            },
                            ticks: {
                                minRotation: 0,  // Ensure labels are parallel to y-axis
                                maxRotation: 0,  // Ensure labels are parallel to y-axis
                                font: {
                                    size: 12,
                                },
                                padding: 0,
                                autoSkip: false,  // Ensure all labels are displayed
                                callback: function (value: any, index: number) { 
                                // Callback to wrap long text labels
                                    const maxLabelWidth = 175;
                                    const labels = this.chart.data.labels;
                                    if (!labels) {
                                        console.log(value);
                                        return;
                                    }
                                    const label = labels[index] as string;

                                    // Wrap long labels into multiple lines by words
                                    const lines = [];
                                    let currentLine = '';
                                    const words = label.split(' ');

                                    for (let word of words) {
                                        const testLine = currentLine + (currentLine ? ' ' : '') + word;
                                        // Measure the width of the test line
                                        const testWidth = this.chart.ctx.measureText(testLine).width;

                                        if (testWidth > maxLabelWidth) {
                                            if (currentLine) {
                                                lines.push(currentLine);
                                            }
                                            currentLine = word;
                                        } else {
                                            currentLine = testLine;
                                        }
                                    }
                                    lines.push(currentLine);

                                    return lines;
                                }
                            },
                        },
                    },
                },
            });
        }
    }, [data, title, backgroundColor]);

    return (
        <div className="w-full max-w-md">
            <div className={height ? height : `h-80`}> {/* Adjust height as needed */}
                <canvas ref={chartRef} className="w-full"/>
            </div>
        </div>
    );
});

export default ImmigrationChart;