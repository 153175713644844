import { DisaggregatedMetric, Metric } from "../services/types";
import populationSvg from '../assets/population.svg';
import povertySvg from '../assets/poverty.svg';
import limitedEngSvg from '../assets/limited-english.svg';
import collegeSvg from '../assets/college.svg';
import unemploymentSvg from '../assets/unemploymentrate.svg';
import homeownerSvg from '../assets/homeowner.svg';
import renterSvg from '../assets/renter.svg';
import medianHomeSvg from '../assets/median-home.svg';
import laborForceSvg from '../assets/laborforce.svg';
import commuteSvg from '../assets/commute.svg';
import internetSvg from '../assets/internet.svg';
import businessTypeSvg from '../assets/business-type.svg';
import raceSvg from '../assets/raceethnicity.svg';
import immigrationSvg from '../assets/immigration.svg';
import hhincomeSvg from '../assets/hhincome.svg';


// #region Organization Metric Mapping
const organizationMetrics: { [orgId: number]: any[] } =
{ 
    // Thurston
    12:
    [
        {
            name: "Demographics",
            ids: [383, 384, 385, 387, 397, 399],
            order: [383, 387, 385],
            charts: [
                {
                    name: "race",
                    id: 397
                },
                {
                    name: "racelat",
                    id: 398
                },
                {
                    name: "immigration",
                    id: 399
                }
            ]
        },
        {
            name: "Workforce",
            ids: [ 386, 388, 389, 390, 391, 392, 393, 395, 396, 400],
            order: [
                [393, 392, 395], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [388, 390, 391], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [389, 386, 396]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 400
                }
            ]
        },
        {
            name: "Economy",
            ids: [394],
            order: [394],
            charts: [
                {
                    name: "empind",
                    id: 394
                }
            ]
        }
    ],
    // Kitsap
    13: 
    [
        {
            name: "Demographics",
            ids: [401, 402, 403, 405, 412, 414],
            order: [401, 405, 403],
            charts: [
                {
                    name: "race",
                    id: 412
                },
                {
                    name: "racelat",
                    id: 413
                },
                {
                    name: "immigration",
                    id: 414
                }
            ]
        },
        {
            name: "Workforce",
            ids: [ 404, 406, 407, 408, 409, 410, 411, 417, 418, 415],
            order: [
                [411, 410, 417], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [406, 408, 409], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [407, 404, 418]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 415
                }
            ]
        },
        {
            name: "Economy",
            ids: [416],
            order: [416],
            charts: [
                {
                    name: "empind",
                    id: 416
                }
            ]
        }
    ],
    // Skagit
    14:
    [
        {
            name: "Demographics",
            ids: [419, 424, 425, 431, 433, 428],
            order: [419, 431, 425],
            charts: [
                {
                    name: "race",
                    id: 433
                },
                {
                    name: "racelat",
                    id: 427
                },
                {
                    name: "immigration",
                    id: 428
                }
            ]
        },
        {
            name: "Workforce",
            ids: [ 429, 423, 430, 420, 421, 422, 432, 434, 436, 426],
            order: [
                [432, 422, 434], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [423, 420, 421], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [430, 429, 436]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 426
                }
            ]
        },
        {
            name: "Economy",
            ids: [435],
            order: [435],
            charts: [
                {
                    name: "empind",
                    id: 435
                }
            ]
        }
    ],
    // Commerce WA - All ADOs
    15:
    [
        {
            name: "Demographics",
            ids: [437, 450, 441, 446, 448, 443],
            order: [437, 446, 441],
            charts: [
                {
                    name: "race",
                    id: 448
                },
                {
                    name: "racelat",
                    id: 442
                },
                {
                    name: "immigration",
                    id: 443
                }
            ]
        },
        {
            name: "Workforce",
            ids: [ 449, 444, 439, 451, 447, 452, 440, 453, 445, 438],
            order: [
                [440, 445, 453], // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Cohort Graduation Rate
                [444, 451, 447], // Metric IDs for Unemployment Rate, Home Owners, Renters
                [439, 449, 452]  // Metric IDs for Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
            ],
            charts: [
                {
                    name: "mhhinc",
                    id: 438
                }
            ]
        },
        {
            name: "Economy",
            ids: [454],
            order: [454],
            charts: [
                {
                    name: "empind",
                    id: 454
                }
            ]
        }
    ]
};


const svgMap: { [orgId: number]: { [key: number]: string }} =
{
    // Thurston
    12: {
        383: populationSvg,  // Population
        384: populationSvg,  // People of Color
        385: limitedEngSvg,  // Limited English Proficiency
        386: internetSvg,    // Households with Internet
        387: povertySvg,     // Households under 200% Poverty
        388: unemploymentSvg,// Unemployment Rate
        389: medianHomeSvg,  // Median Home Value
        390: homeownerSvg,   // Home Owners
        391: renterSvg,      // Renters
        392: collegeSvg,     // Individuals with College Degrees
        393: laborForceSvg,  // Labor Force
        394: businessTypeSvg, // Employment by Industry'
        395: collegeSvg,     // Four-Year Graduation Cohort Rate
        396: commuteSvg,     // Workers within 45 Minutes by Transit
        397: raceSvg,        // Race & Ethnicity
        399: immigrationSvg, // Top Countries of Immigration
        400: hhincomeSvg,    // HH Income  
        // add other indicator svg mappings below
    },
    // Kitsap
    13: {
        401: populationSvg,  // Population
        402: populationSvg,  // People of Color
        403: limitedEngSvg,  // Limited English Proficiency
        404: internetSvg,    // Households with Internet
        405: povertySvg,     // Households under 200% Poverty
        406: unemploymentSvg,// Unemployment Rate
        407: medianHomeSvg,  // Median Home Value
        408: homeownerSvg,   // Home Owners
        409: renterSvg,      // Renters
        410: collegeSvg,     // Individuals with College Degrees
        411: laborForceSvg,  // Labor Force
        416: businessTypeSvg, // Employment by Industry'
        417: collegeSvg,     // Four-Year Graduation Cohort Rate
        418: commuteSvg,     // Workers within 45 Minutes by Transit
        412: raceSvg,        // Race & Ethnicity
        414: immigrationSvg, // Top Countries of Immigration
        415: hhincomeSvg,    // HH Income  
        // add other indicator svg mappings below
    },
    // Skagit
    14: {
        419: populationSvg,  // Population
        424: populationSvg,  // People of Color
        425: limitedEngSvg,  // Limited English Proficiency
        429: internetSvg,    // Households with Internet
        431: povertySvg,     // Households under 200% Poverty
        423: unemploymentSvg,// Unemployment Rate
        430: medianHomeSvg,  // Median Home Value
        420: homeownerSvg,   // Home Owners
        421: renterSvg,      // Renters
        422: collegeSvg,     // Individuals with College Degrees
        432: laborForceSvg,  // Labor Force
        435: businessTypeSvg, // Employment by Industry'
        434: collegeSvg,     // Four-Year Graduation Cohort Rate
        436: commuteSvg,     // Workers within 45 Minutes by Transit
        433: raceSvg,        // Race & Ethnicity
        428: immigrationSvg, // Top Countries of Immigration
        426: hhincomeSvg,    // HH Income  
        // add other indicator svg mappings below
    },
    // Commerce All ADOs
    15: {
        437: populationSvg,  // Population
        450: populationSvg,  // People of Color
        441: limitedEngSvg,  // Limited English Proficiency
        449: internetSvg,    // Households with Internet
        446: povertySvg,     // Households under 200% Poverty
        444: unemploymentSvg,// Unemployment Rate
        439: medianHomeSvg,  // Median Home Value
        451: homeownerSvg,   // Home Owners
        447: renterSvg,      // Renters
        445: collegeSvg,     // Individuals with College Degrees
        440: laborForceSvg,  // Labor Force
        454: businessTypeSvg, // Employment by Industry'
        453: collegeSvg,     // Four-Year Graduation Cohort Rate
        452: commuteSvg,     // Workers within 45 Minutes by Transit
        448: raceSvg,        // Race & Ethnicity
        443: immigrationSvg, // Top Countries of Immigration
        438: hhincomeSvg,    // HH Income  
        // add other indicator svg mappings below
    }
};

// #endregion



// getMetricGroupIds - get the metric id values for a specific grouping 
const getMetricGroupIds = (name: string) => {

    let ids = [];
    
    if(organizationMetrics[orgId] != undefined){
        const group: any = organizationMetrics[orgId].find((x: { name: string; }) => x.name === name);

        if(group != undefined){
            ids = group.ids;
        }
    }

    return ids;
}



// Store our organization Id where we can access it later
export var orgId: number = 12;
export const setOrgId = (id: number) => {
    orgId = id;
}

// Store our overlapping county fips codes for BLS and TRS data queries
export var countyId: string[] = [];
export const setCountyIds = (id: string[]) => {
    countyId = id;
}
export const getCountyIds = () => {
    return countyId;
}



// getSvgMap - get our svg mapping for the given organization and metric id
export const getSvgMap = (id: number) => {
    let svg = "";
    if(svgMap[orgId] != undefined){
        svg = svgMap[orgId][id];
    }
    return svg;
}

// getMetricGroupOrderIds - get the ordering for a metric group
export const getMetricGroupOrderIds = (name: string) => {
    let orderIds = [];
    
    if(organizationMetrics[orgId] != undefined){
        const group: any = organizationMetrics[orgId].find((x: { name: string; }) => x.name === name);

        if(group != undefined){
            orderIds = group.order;
        }
    }

    return orderIds;
}

// getMetricGroupChartId - get a metric id given the group and chart key
export const getMetricGroupChartId = ( group: string, key: string) => {
    let id = -1;

    if(organizationMetrics[orgId] != undefined){
        const g = organizationMetrics[orgId].find((x: { name: string; }) => x.name === group);
        if(g != undefined){
            
            if(g.charts != undefined){

                const chart = g.charts.find((x: { name: string; }) => x.name === key);
                if(chart != undefined){
                    id = chart.id;
                }
            }
        }
    }

    return id;
}

// // getInteractionsLayerId - each organization will have its own interactions layer id
// // Updated to query by interaction tag to remove hard-coding
// export const getInteractionsLayerId = async () => {

//     var layerId = '';

//     const endpoint = `https://t47lzv8rn2.execute-api.us-east-1.amazonaws.com/staging/layers/search?organizationId=${orgId}&tags=ado_business_interactions`;
//     let searchLayerResponse = await fetch(endpoint, { method: 'GET' });
//     const layerDetails = await searchLayerResponse.json();
//     console.log("layer details: ", layerDetails);
//     layerId = layerDetails.data[0].layer_id;
//     console.log("orgId, layerId: ", orgId, layerId);


//     // Legacy - remove when reference no longer needed
//     // switch(orgId){
//     //     case 12: layerId = '25'; break;
//     //     case 13: layerId = '29'; break;
//     //     case 14: layerId = '30'; break;
//     //     default: layerId = '25';
//     // }

//     return layerId;
// };

// isDataLoaded - utility function to check that data is available for the dashboard
export const isDataLoaded = (data: any[], indicators: any[], tractData?: any[]) => 
    data.length > 0 && indicators.length > 0 && (!tractData || tractData.length > 0);


export const categorizeData = (tractData: DisaggregatedMetric[], disaggregatedData: DisaggregatedMetric[]) => {

    const categories = {
        Demographics: getMetricGroupIds("Demographics"), // Metric IDs for Households under 200% Poverty, Population, Limited English Proficiency, People of Color
        Workforce: getMetricGroupIds("Workforce"), // Metric IDs for Labor Force, Individuals with College Degrees, Four-Year Graduation Cohort Rate, Unemployment Rate, Home Owners, Renters, Median Home Value, Households with Internet, Workers within 45 Minutes by Transit
        Economy: getMetricGroupIds("Economy"), // Metric IDs for Employment by Industry
        Interactions: [] // Will be populated with other data
    };

    const categorizedData = {
        Demographics: [] as Metric[],
        Workforce: [] as Metric[],
        Economy: [] as Metric[],
        Interactions: [] as Metric[]
    };

    // data.forEach((item) => {
    //     if (categories.Demographics.includes(item.id)) {
    //         categorizedData.Demographics.push(item);
    //     } else if (categories.Workforce.includes(item.id)) {
    //         categorizedData.Workforce.push(item);
    //     }
    // });

    // Filter out metric 399 from disaggregatedData
    const filteredData = disaggregatedData.filter(item => item.id !== 399);

    filteredData.forEach((item) => {
        if (categories.Demographics.includes(item.id)) {
            categorizedData.Demographics.push(item);
        } else if (categories.Workforce.includes(item.id)) {
            categorizedData.Workforce.push(item);
        } else if (categories.Economy.includes(item.id)) {
            categorizedData.Economy.push(item);
        }
    });

    // Ensure that tract data for top countries of immigration are added (to create chart label)
    if (tractData) {
        const requiredMetrics = [399, 414, 428]; // List of metric IDs to check
        const metricsToAdd = requiredMetrics.map(id => tractData.find(item => item.id === id)).filter(metric => metric !== undefined) as typeof tractData;

        metricsToAdd.forEach(metric => {
            if (metric && !categorizedData.Demographics.some(item => item.id === metric.id)) {
                categorizedData.Demographics.push(metric);
            }
        });
    }

    return categorizedData;
};


