import { useState, useEffect } from 'react';

const useFetchMetricMetadata = (id: string | number) => {
    const [data, setData] = useState<any>(null); // Adjust the type as necessary
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        const fetchMetricMetadata = async () => {
            if (id === null || id === undefined) {
                console.log('No id provided to fetch metric metadata');
                return;
            } 

            try {
                const response = await fetch('https://cai-dashboard-backend.vercel.app/api/getmetricmetadata', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ id }),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setData(result.data);
            } catch (error: any) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchMetricMetadata();
    }, [id]);

    return { data, loading, error };
};

export default useFetchMetricMetadata;