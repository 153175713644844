import { memo, useState } from "react";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import { DisaggregatedMetric } from "../services/types";
import BarChart from "./BarChart";
import { getMetricGroupChartId } from "../utils/dashboardUtils";
import { formatValue } from "../utils/helpers";

const desiredOrder = [
    "Construction",
    "Resources",
    "Manufacturing",
    "Wholesale, Utilities",
    "Retail",
    "Finance, Insurance & Real Estate",
    "IT & Professional Services",
    "Health & Other Services",
    "Consumer Services",
    "Education",
    "Government",
    "Unclassified"
];

interface EmploymentByIndustryChartProps {
    disaggregatedData: DisaggregatedMetric[];
}

const EmploymentByIndustryChart = memo(({ disaggregatedData }: EmploymentByIndustryChartProps) => {
    const [chartData, setChartData] = useState<{ label: string, value: number, rawValue: number }[]>([]);

    useDeepCompareEffect(() => {
        if (!disaggregatedData) return;

        const industryData = disaggregatedData.find(data => data.id === getMetricGroupChartId("Economy", "empind"));

        if (!industryData) return;

        const version = industryData.versions[0];
        if (!version) return;

        const tallyMap = new Map<string, number>();
        const rawValueMap = new Map<string, number>();

        version.disaggregations.forEach(d => {
            const percentage = (d.value / version.value);
            tallyMap.set(d.name, percentage);
            rawValueMap.set(d.name, d.value); // Store the raw value
        });

        const orderedData = desiredOrder.map(label => ({
            label,
            value: tallyMap.get(label) ?? 0,
            rawValue: rawValueMap.get(label) ?? 0
        }));

        setChartData(orderedData);
    }, [disaggregatedData]);

    return <BarChart data={chartData} title="Employment By Industry" height="h-64" formatter={(value) => `${formatValue(value, 'percentage')}`} />;
})

export default EmploymentByIndustryChart;