import { memo, useRef } from "react";
import { Chart } from "chart.js/auto";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import { formatValue } from "../utils/helpers";

interface BarChartProps {
    data: { label: string, value: number, rawValue: number }[];
    title: string;
    max?: number;
    backgroundColor?: string;
    barColors?: string[];
    height?: string;
    formatter?: (value: number) => string;
}

const BarChart: React.FC<BarChartProps> = memo(({ data, title, max, backgroundColor = '#626262', barColors, height, formatter }) => {
    // Register the plugin to all charts:
    Chart.register(ChartDataLabels);
    const chartRef = useRef<HTMLCanvasElement>(null);
    const chartInstance = useRef<Chart<"bar"> | null>(null);

    useDeepCompareEffect(() => {
        const ctx = chartRef.current?.getContext("2d");
        if (ctx) {
            // Destroy existing chart instance if it exists
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }

            // Extract labels and data
            const labels = data.map(d => d.label);
            const chartData = data.map(d => d.value);

            // Set max and min values for label padding
            const maxValue = max ?? Math.max(...chartData) * 1.5;
            const minValue = -maxValue * .25;

            // Use provided barColors or fallback to backgroundColor for all bars
            const colors = barColors && barColors.length === data.length
                ? barColors
                : new Array(data.length).fill(backgroundColor);

            chartInstance.current = new Chart(ctx, {
                type: "bar",
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: title,
                            data: chartData,
                            backgroundColor: colors,
                        },
                    ],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,  // Allows the chart to take the full height
                    indexAxis: 'y',
                    plugins: {
                        legend: {
                            display: false,
                        },
                        datalabels: {
                            anchor: 'start',
                            align: 'start',
                            formatter: formatter || ((value) => value ? `${value.toFixed(0)}` : ''),
                            font: {
                                size: 12,
                                weight: 'bold'
                            },
                            offset: 0
                        },
                        tooltip: {
                            callbacks: {
                                label: (context) => {
                                    const rawValue = data[context.dataIndex].rawValue; // Get the raw value from the data array
                                    return `${formatValue(rawValue, 'integer')}`; // Display raw value in tooltip
                                }
                            }
                        },
                    },
                    scales: {
                        x: {
                            beginAtZero: true,
                            min: minValue,
                            max: maxValue,
                            ticks: {
                                display: false,
                            },
                            grid: {
                                display: false,
                                drawTicks: false
                            },
                            border: {
                                display: false,
                                dash: [2, 4]
                            }
                        },
                        y: {
                            grid: {
                                display: false,
                                drawTicks: true,
                            },
                            border: {
                                display: false
                            },
                            ticks: {
                                minRotation: 0,  // Ensure labels are parallel to y-axis
                                maxRotation: 0,  // Ensure labels are parallel to y-axis
                                font: {
                                    size: 12,
                                },
                                padding: 0,
                                autoSkip: false,  // Ensure all labels are displayed
                                callback: function (value: any, index: number) { 
                                // Callback to wrap long text labels
                                    const maxLabelWidth = 175;
                                    const labels = this.chart.data.labels;
                                    if (!labels) {
                                        console.log(value);
                                        return;
                                    }
                                    const label = labels[index] as string;

                                    // Wrap long labels into multiple lines by words
                                    const lines = [];
                                    let currentLine = '';
                                    const words = label.split(' ');

                                    for (let word of words) {
                                        const testLine = currentLine + (currentLine ? ' ' : '') + word;
                                        // Measure the width of the test line
                                        const testWidth = this.chart.ctx.measureText(testLine).width;

                                        if (testWidth > maxLabelWidth) {
                                            if (currentLine) {
                                                lines.push(currentLine);
                                            }
                                            currentLine = word;
                                        } else {
                                            currentLine = testLine;
                                        }
                                    }
                                    lines.push(currentLine);

                                    return lines;
                                }
                            },
                        },
                    },
                },
            });
        }
    }, [data, title, max, backgroundColor, formatter]);

    return (
        <div className="w-full max-w-md">
            <div className={height ? height : `h-80`}> {/* Adjust height as needed */}
                <canvas ref={chartRef} className="w-full"/>
            </div>
        </div>
    );
})

export default BarChart;