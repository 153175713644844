import { formatValue } from "../utils/helpers";
import metricMetadataIcon from '../assets/metric-metadata-icon.svg';
import useFetchMetricMetadata from "../hooks/useFetchMetricMetadata";
import { useState } from "react";
import MetricMetadataModal from "./MetricMetadataModal";

interface DisaggregateMetricCardProps {
    id: number;
    title: string;
    subText: string;
    value?: number;
    unit?: string;
    svg: string;
    showMetadataIcon?: boolean;
};

const DisaggregateMetricCard = ({ id, title, subText, value, unit, svg, showMetadataIcon = true }: DisaggregateMetricCardProps) => {
    const [showMetadata, setShowMetadata] = useState(false);
    const { data: metadata } = useFetchMetricMetadata(id);

    const handleClick = () => {
        setShowMetadata(prev => !prev);
        console.log('metricid:', id)
    }

    return (
        <div className="ml-2 mr-4 my-2 w-48">
            <div className="flex flex-col items-start w-full">
                <div className="flex items-end w-28 justify-between">
                    <img src={svg} alt={`${title} icon`} className="w-10 h-10 text-blue-500" />
                    {showMetadataIcon && 
                    <button className="mb-1" onClick={handleClick}>
                        <img src={metricMetadataIcon} alt="Metric metadata icon" />
                    </button>
                    }
                </div>
                <p className="text-color-cai-gray text-sm font-bold w-full">{title}</p>
                <div className="flex items-baseline w-full justify-start">
                    <p className="text-color-cai-gray text-xs mr-2">{subText} </p>
                    <h2 className="text-color-cai-dashboard text-md font-bold">{value && unit && formatValue(value, unit)}</h2>
                </div>
            </div>
            <MetricMetadataModal
                isOpen={showMetadata}
                onClose={handleClick}
                metadata={metadata}
                svg={svg}
            />
        </div>
    );
};


export default DisaggregateMetricCard;
