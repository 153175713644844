import { memo, useState } from "react";
import useDeepCompareEffect from "../hooks/useDeepCompareEffect";
import { DisaggregatedMetric } from "../services/types";
import BarChart from "./BarChart";
import { getMetricGroupChartId } from "../utils/dashboardUtils";
import { formatValue } from "../utils/helpers";

const desiredOrder = [
    "More than $200k",
    "$150k - $200k",
    "$100k - $150k",
    "$75k - $100k",
    "$50k - $75k",
    "$35k - $50k",
    "$20k - $35k",
    "Less than $20k"
];


interface HouseholdIncomeChartProps {
    disaggregatedData: DisaggregatedMetric[];
}

const HouseholdIncomeChart = memo(({ disaggregatedData }: HouseholdIncomeChartProps) => {
    const [chartData, setChartData] = useState<{ label: string, value: number, rawValue: number }[]>([]);

    useDeepCompareEffect(() => {
        if (!disaggregatedData) return;

        const hhIncomeData = disaggregatedData.find(data => data.id === getMetricGroupChartId("Workforce", "mhhinc"));

        if (!hhIncomeData) return;

        const version = hhIncomeData.versions[0];
        if (!version) return;

        const tallyMap = new Map<string, number>();
        const rawValueMap = new Map<string, number>();

        version.disaggregations.forEach(d => {
            const percentage = (d.value / version.value);
            tallyMap.set(d.name, percentage);
            rawValueMap.set(d.name, d.value); // Store the raw value
        });

        const orderedData = desiredOrder.map(label => ({
            label,
            value: tallyMap.get(label) ?? 0,
            rawValue: rawValueMap.get(label) ?? 0
        }));

        setChartData(orderedData);
    }, [disaggregatedData]);

    return <BarChart data={chartData} title="Household Income" formatter={(value) => `${formatValue(value, 'percentage')}`} height="h-44" />;
})

export default HouseholdIncomeChart;