import { useEffect, useRef } from 'react';
import {isEqual} from 'lodash';

function useDeepCompareEffect(callback: () => void, dependencies: any[]) {
  const previousDependenciesRef = useRef<any[]>();

  useEffect(() => {
    if (!isEqual(previousDependenciesRef.current, dependencies)) {
      callback();
    }
    previousDependenciesRef.current = dependencies;
  }, [callback, dependencies]);
}

export default useDeepCompareEffect;
